<template>
	<div class="document-table position-relative w-100 h-100 px-4 py-3 d-flex flex-column overflow-hidden">
		<table-summary title="Document" filter="documentNo" sort="createdAt"
		               v-bind:order="-1"
		               v-bind:fields="fields"
		               v-bind:data-array="documents"
		               v-on:add-new="addDocument"
		               v-on:row-clicked="navigate($event)"></table-summary>
	</div>
</template>

<script>
import TableSummary from "@/components/functional/table/TableSummary";
import {format} from "date-fns";

export default {
	name: "DocumentTable",
	
	components: {
		TableSummary,
	},
	
	data() {
		return {
			fields: [
				{ display: "Document No.", key: "documentNo", classBinding: ["f-2"] },
				{ display: "Name", key: "name", classBinding: ["f-5"] },
				{ display: "type", key: "type", classBinding: [] },
				{ display: "Uploaded At", key: "createdAt", classBinding: [] },
			],
		}
	},
	
	computed: {
		documents() {
			return this.$store.state.contents.documents.map(document => {
				const obj = {...document};
				obj.createdAt = format(new Date(document.createdAt), "yyyy-MM-dd");
				return obj;
			});
		}
	},
	
	methods: {
		async addDocument() {
			await this.$router.push("/supplier/document/add");
		},
		
		async navigate(dataObj) {
			await this.$router.push(`/supplier/document/${dataObj._id}`);
		}
	}
}
</script>

<style lang="scss" scoped>

</style>